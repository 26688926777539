

















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';
import { AuthStoreActions } from '@/store/auth.store';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const AuthStore = namespace('auth');

@Component({
    mixins: [validationMixin],
    validations: {
        password: {
            required,
            minLength: minLength(8)
        },
        passwordRepeat: {
            sameAsPassword: sameAs('password')
        }
    }
})
export default class PasswordView extends mixins(ErrorMessageHandlerMixin, BaseMixin, AxiosErrorHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.RESET_PASSWORD)
  private resetPasswordAction!: (payload: { token: string, password: string }) => Promise<any>;

  private token?: string;

  private alertMessage: string = '';
  private showAlert: boolean = false;
  
  private password: string | null = null;
  private passwordRepeat: string | null = null;

  private isLoading: boolean = false;
  private isLocked: boolean = false;
  
  private showForm: boolean = true;

  created() {
    const token = this.$route.query.token;
    if (!token) {
      this.isLocked = true;
      this.alertMessage = this.$t('PASSWORD.INVALID_RESET_TOKEN').toString();
      this.showAlert = true;
    } else {
      this.token = token as string;
    }
  }

  private async saveData() {
    // Trigger validation
    this.$v.$touch();

    if (!this.$v.$invalid && !this.isLoading) {
      try {
        this.isLoading = true;
        await this.resetPasswordAction({ token: this.token!, password: this.password! });
        this.showForm = false;
      } catch (e) {
        this.handleAxiosError(e, () => {
          switch (e.status) {
            case 422:
              this.isLocked = true;
              this.alertMessage = this.$t('PASSWORD.INVALID_RESET_TOKEN').toString();
              this.showAlert = true;
              break;
            default:
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
          }
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
}
